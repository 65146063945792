<template>
  <div>
    <!-- 分销管理 -->
    <en-table-layout
      :tips="false"
      :toolbar="false"
      :tableData="tableData"
      :loading="loading"
    >
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="agent_level" label="等级编号" />
        <el-table-column prop="agent_name" label="等级名称" />
        <el-table-column label="分销佣金比例">
          <template slot-scope="scope">{{scope.row.commission_rate}}%</template>
        </el-table-column>
        <el-table-column label="操作" width="280">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleEdit(scope.row)">修改</el-button>
          </template>
        </el-table-column>
      </template>
    </en-table-layout>
    <!--群发站内信 dialog-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form :model="levelInfoForm" :rules="levelInfoRules" ref="levelInfo" label-width="150px">
        <!--等级名称-->
        <el-form-item label="等级名称" prop="agent_name">
          <el-input v-model="levelInfoForm.agent_name"></el-input>
        </el-form-item>
        <!--分销佣金比例-->
        <el-form-item label="分销佣金比例" prop="commission_rate">
          <el-input v-model.number="levelInfoForm.commission_rate" width="200">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="btn-default-dialog-no" @click="dialogVisible = false">取 消</el-button>
        <el-button class="btn-default-dialog-yes" @click="handleClickLevelBtn()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as API_Distribution from "@/api/distribution";
// import { RegExp } from "bms-common/ui-utils";
export default {
  name: "salesLevelList",
  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表数据 */
      tableData: [],

      /** 修改等级 */

      dialogVisible: false,
      levelInfoForm: {},
      dialogTitle: "编辑",
      levelInfoRules: {
        agent_name: [
          { required: true, message: "请填写等级名称", trigger: "blur" }
        ],
        commission_rate: [
          { required: true, message: "请填写分销佣金比例", trigger: "blur" }
        ]
      }
    };
  },
  watch: {
    // "addMemberForm.region"(next, prev) {
    //   if (next && next.length) {
    //     this.$refs.addMemberForm.clearValidate("region");
    //   }
    // }
  },
  mounted() {
    this.GET_LevelList();
  },
  beforeRouteUpdate(to, from, next) {
    this.GET_LevelList();
    next();
  },
  methods: {
    /** 修改等级确定 */
    handleClickLevelBtn() {
      this.$refs["levelInfo"].validate(valid => {
        if (valid) {
          console.log(this.levelInfoForm);
          API_Distribution.editDistributorLevel(this.levelInfoForm)
            .then(res => {
              this.$message.success("修改成功！");
              this.dialogVisible = false;
              this.GET_LevelList();
            })
            .catch(_ => {});
        } else {
          this.$message.error("表单填写有误，请检查！");
          return false;
        }
      });
    },
    /** 修改等级 */
    handleEdit(row) {
      this.levelInfoForm = {
        id: row.id,
        agent_name: row.agent_name,
        commission_rate: row.commission_rate
      };
      this.dialogVisible = true;
    },
    /** 获取列表 */
    GET_LevelList() {
      API_Distribution.getDistributorLevelList()
        .then(res => {
          this.tableData = res;
        })
        .catch(() => {});
    }
  }
};
</script>

<style type="text/scss" lang="scss" scoped>
::v-deep .el-dialog {
  width: 30%;
}
::v-deep .el-form-item .el-input {
  width: 150px;
}
.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
div.toolbar-btns {
  display: contents;
}
</style>
