/**
 * 分销相关API
 */

import request from '@/utils/request'

/**
 * 获取个人提成模板列表
 * @param params
 */
export function getPerAccomplishmentTpl(params) {
  return request({
    url: 'admin/distribution/commission-tpl',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 添加提成模板
 * @param params
 */
export function addPerTpl(params) {
  return request({
    url: 'admin/distribution/commission-tpl',
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 修改提成模板
 * @param params
 */
export function editPerTpl(params) {
  return request({
    url: `admin/distribution/commission-tpl/${params.id}`,
    method: 'put',
    loading: false,
    params
  })
}

/**
 * 删除提成模板
 * @param id
 */
export function delPerTpl(id) {
  return request({
    url: `admin/distribution/commission-tpl/${id}`,
    method: 'delete',
    loading: false
  })
}

/**
 * 获取提成模板
 * @param id
 */
export function getPerTpl(id) {
  return request({
    url: `admin/distribution/commission-tpl/${id}`,
    method: 'get',
    loading: false
  })
}

/**
 * 获取分销模板升级日志
 */
export function getUpgradeLogs(params) {
  return request({
    url: 'admin/distribution/upgradelog',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 分销商等级列表
 * @param params
 */
export function getDistributorLevelList(params) {
  return request({
    url: '/admin/distribution/level/list',
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 分销商详情
 * @param params
 */
export function getDistributorDetailList(params) {
  return request({
    url: '/admin/distribution/member',
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 分销商等级修改
 * @param params
 */
export function editDistributorLevel(params) {
  return request({
    url: `admin/distribution/level/${params.id}`,
    method: 'put',
    loading: false,
    params,
    log: {
      type: '2',
      name: '客户代理等级修改'
    }
  })
}

/**
 * 获取分销商列表查询
 * @param params
 */
export function getDistributorList(params) {
  return request({
    url: 'admin/distribution/member/page',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 修改分销信息
 * @param params
 */
export function editDistributorMember(params) {
  return request({
    url: '/admin/distribution/member/edit',
    method: 'put',
    loading: false,
    params,
    log: {
      type: '2',
      name: '客户分销等级修改'
    }
  })
}

/**
 * 修改分销商模板
 * @param params
 */
export function modifyTpl(params) {
  return request({
    url: `admin/distribution/member/tpl`,
    method: 'put',
    loading: false,
    params
  })
}

/**
 * 获取订单数量图 = 订单数
 * @param params
 */
export function getOrderCountChart(params) {
  return request({
    url: 'admin/distribution/statistic/count',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取订单金额图 = 营业额
 * @param params
 */
export function getOrderAmountChart(params) {
  return request({
    url: 'admin/distribution/statistic/order',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取订单返现图 = 利润额
 * @param params
 */
export function getProfitChart(params) {
  return request({
    url: 'admin/distribution/statistic/push',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 业绩列表
 * @param params
 */
export function getAchievementList(params) {
  return request({
    url: 'admin/distribution/bill/total',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 账单列表
 * @param params
 */
export function getBillList(params) {
  return request({
    url: 'admin/distribution/bill/member',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取某个账单详情
 * @param id
 */
export function getDisBillDetails(id) {
  return request({
    url: `admin/distribution/bill/member/${id}`,
    method: 'get',
    loading: false
  })
}

/**
 * 分销订单列表
 * @param params
 */
export function getDisOrderList(params) {
  return request({
    url: 'admin/distribution/order',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 分销退款单查询
 * @param params
 */
export function getDisRefundOrderList(params) {
  return request({
    url: 'admin/distribution/order/sellback',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取某个分销商下级业绩
 * @param params
 */
export function getDisBillDown(params) {
  return request({
    url: 'admin/distribution/bill/member/down',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 提现申请/提现记录列表
 * @param params
 */
export function getWithdrawApplyList(params) {
  return request({
    url: 'admin/distribution/withdraw/apply',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 审核提现申请
 * @param params
 */
export function authWithdrawBatchApply(audit_result, params) {
  return request({
    url: `admin/distribution/withdraw/batch/auditing?audit_result=${audit_result}`,
    method: 'post',
    loading: false,
    data: params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * 设为已转账
 * @param params
 */
export function batchSetTransferAccounts(params) {
  return request({
    url: 'admin/distribution/withdraw/batch/account/paid',
    method: 'post',
    loading: false,
    data: params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * 获取提现设置
 * @param params
 */
export function getWithDrawSettings() {
  return request({
    url: 'admin/distribution/settings',
    method: 'get',
    loading: false
  })
}

/**
 * 保存提现设置
 * @param params
 */
export function reserveWithDrawSettings(params) {
  return request({
    url: 'admin/distribution/settings',
    method: 'put',
    loading: false,
    params
  })
}

/**
 * 导出提现申请
 * @param params
 */
export function exportWithdrawApplyList(params) {
  return request({
    url: 'admin/distribution/withdraw/export',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询分销活动列表，用于分页查询
 * @param params
 */
export function getDistributionPage(params) {
  return request({
    url: '/admin/distribution/agentGoods/page',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 删除分销活动
 * @param params
 */
export function deteleDistributionPage(id) {
  return request({
    url: `/admin/distribution/agentGoods/${id}`,
    method: 'DELETE',
    loading: false,
    log: {
      type: '2',
      name: '分销活动删除'
    }
  })
}

/**
 *获得所有分销商品ID
 * @param params
 */
export function getGoodIds() {
  return request({
    url: `/admin/distribution/agentGoods/goodIds`,
    method: 'get',
    loading: false
  })
}
/**
 * 查询活动商品列表，用于分页查询
 * @param params
 */
export function getGoodsActivity(params) {
  return request({
    url: `/admin/active/goodsActivity/page2`,
    method: 'post',
    loading: false,
    params
  })
}
/**
 * 添加分销活动
 * @param params
 */
export function addGoodsActivity(ids) {
  return request({
    url: `/admin/distribution/agentGoods/add/${ids}`,
    method: 'post',
    loading: false,
    log: {
      type: '2',
      name: '分销活动添加'
    }
  })
}

/** 佣金统计开始 */

/**
 * 佣金收益统计
 * @param params
 */
export function getAgentMoney(params) {
  params.cycle_type = 'TIME'
  return request({
    url: 'admin/AgentStatistic/commission',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 订单数统计
 * @param params
 */
export function getOrderNum(params) {
  params.cycle_type = 'TIME'
  return request({
    url: 'admin/AgentStatistic/orderNum',
    method: 'get',
    loading: false,
    params
  })
}

/** 佣金统计结束 */
